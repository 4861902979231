@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Lato&family=Parisienne&display=swap');

:root {
  --primary-color: black;
  --dark-charcoal-color: #333;
  --light-color: #DCCEC3;
  --danger-color: #dc3545;
  --success-color: #28a745;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Josefin Sans', sans-serif;
  /* font-size: 16px; */
  background-color: #FBFBFD;
}

/* 62.5% is 10px. makes it more responsive, and easy to calculate rem values based on it. 1rem is 16px */
/* html {
  font-size: 62.5%
  font-size: 10px;
} */

html {
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
}

@font-face {
    font-family: black-chancery;
    src: url(BLKCHCRY.TTF);
}

img:hover, button:hover {
  cursor: pointer;
}

/*================== NAV SECTION =====================*/
#custom-nav {
  background-color: var(--primary-color);
}

.navbar-light .navbar-nav .nav-link {
  color: white;
  transition: .5s;
  font-family: 'Lato', sans-serif;
  position: relative;
}

.navbar-light .navbar-nav .nav-link:hover {
  color: white !important;
  transition: all 3s !important;
}

.navbar-light .navbar-nav .nav-link:hover:after {
  content: ".";
  color: black;
  position: absolute;
  bottom: 3px;
  transition: .5s;
  left: 1px;
  border-bottom: 2px solid white;
  width: 100%;
  transition: all 3s !important;
}

.navbar-light .navbar-nav .nav-link.active {
  color: white;
}

.navbar-light .navbar-toggler-icon {
  background-color: white;
}

.navbar-light .navbar-toggler-icon {
  background-color: transparent !important;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='40'><rect y='5' width='100' height='3' style='fill:rgb(255,255,255);'></rect><rect y='15' width='100' height='3' style='fill:rgb(255,255,255);'></rect><rect y='25' width='100' height='3' style='fill:rgb(255,255,255);'></rect></svg>");
}

/*================== HEADER SECTION =====================*/
/* MOBILE STYLINGS */

.header {
  background: url(./img/home/hero-2.jpg) no-repeat center center/cover;
  height: 75vh;
  width: 100%;
  position: relative; /* this is the origin from where the top and left start to count, below in the .logo-box class */
}

.logo-box {
  position: absolute;
  top: 4%;
  left: 4%;
  text-align: center;
}

.logo {
  height: 55px;
}

.title {
  font-family: black-chancery;
  color: black;
  font-size: 30px;
  margin-bottom: 3px;
}

.city {
  font-family: 'Lato', sans-serif;
  font-size: 12px;
  letter-spacing: 3px;
}

.title-city {
  float: right;
  padding-top: 13px;
}

.socials-box {
  position: absolute;
  bottom: 4%;
  right: 4%;
  text-align: center;
  border: 1px solid white;
  border-radius: 5px;
  padding: 3px;
}

.socials-box i {
  font-size: 25px;
  color: white;
  margin: 7px;
}

/* HEADER - TABLET SIZES ON UP */
@media only screen and (min-width: 480px) {
  .logo-box {
    position: absolute;
    top: 4%;
    left: 4%;
    text-align: center;
  }

  .logo {
    height: 80px;
  }

  .title {
    color: black;
    font-size: 37px;
    margin-top: -16px;
    margin-bottom: 1px;
    letter-spacing: 1px;

    animation-name: moveInLeft;
    /* these two properties are all you need to kick off your 'moveInLeft' animation below */
    animation-duration: 1s;
    animation-timing-function: ease-out;
  }

    /* moves the apostrophe ' in charpier's to the correct position */
  .title > span > span {
    position: relative;
    top: 0px;
    right: 1px;
  }

  .city {
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    letter-spacing: 3px;


    animation: moveInRight 1s ease-out;
  }

  .title-city {
    float: none;
  }
}
/*================== BREAD CARDS SECTION =====================*/
/* MOBILE STYLINGS */

.welcome {
  padding: 30px 40px;
  border-bottom: 1.5px solid black;
  border-radius: 0 0 6px 6px;
  text-align: center;
  color: white;
  background-color: black;
}

.welcome h6 {
  margin: 0;
  font-size: 18px;
  line-height: 1.5;
}

.our-breads {
  margin: 60px 0 30px 0;
  /* border-bottom: 2px solid black; */
}

/* cannot for the life of me figure out how to center align text over a bottom underline. if the line-spacing increases you'll see */
/* this 'our breads' section also needs to be styled better */
.our-breads h2 {
  content: '';
  font-family: 'Lato', sans-serif;
  letter-spacing: .2em;
  display: inline;
  border-bottom: 2px solid #1c2124;
  padding-bottom: 8px;
  padding-left: 8px;
}

.bread-category-row {
  margin: 0 auto;
}

.bread-category-title {
  display: inline-block;
  position: relative;
  padding: 0 .5em;
  margin: 0 auto 20px auto;
  font-size: 18px;
}

.bread-category {
  margin: 80px 0 80px 0;
}

.separating-line {
    display: block;
    height: 3px;
    width: 80%;
    border: 0;
    border-top: 1px solid #332e2e;
    margin: 80px auto;
    padding: 0;
}

.bread-category-title span {
  position: relative;
}

/* this mess is just adding the lines that come out of the category titles, next to the fleur-de-lis */
.bread-category-title::before,
.bread-category-title::after {
  content:"";
  position: absolute;
  width:10px;
  height: 1px;
  border-top: 1px solid black;
  top:45%;
}

.bread-category-title::after {
  left:100%;
}

.bread-category-title::before {
  right: 100%;
}

.fleur-left {
  display: inline-block;
  margin: 0 13px 8px 0;
}

.fleur-right {
  margin: 0 0 8px 13px;
}


.fleur-left, .fleur-right {
  height: 15px;
}

/* .bread-card:hover {
  border: 2px solid black;
} */

.bread-card-body {
  margin: 0;
  padding: 9px;
  background-color: #36454f;
  color: white;
  border-bottom-left-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}

.card-title {
  margin: 0;
  font-size: 16px;
}

.callout-card {
  min-height: 232px;
}

.callout-list {
  list-style-type: none;
}

.extras-box {
  background-color: #36454f;
  color: white;
  border-radius: 6px;
  outline: 2px solid white;
  outline-offset: -6px;
  text-align: center;
  padding: 10px 10px;
  margin-top: 20px;
}

.extras-box p {
  margin: 0;
}

.missing {
  display: inline;
}

/* BREAD CARD SECTION - TABLET SIZES ON UP */
@media only screen and (min-width: 480px) {
  .bread-category-title {
    font-size: 1.5rem;
    letter-spacing: .05em;
  }

  .bread-category-title span {
    position: relative;
  }

  /* this mess is just adding the lines that come out of the category titles, next to the fleur-de-lis */
  .bread-category-title::before,
  .bread-category-title::after {
    content:"";
    position: absolute;
    width:30px;
    height: 1px;
    border-top: 1px solid black;
    top:45%;
  }

  .bread-category-title::after {
    left:100%;
  }

  .bread-category-title::before {
    right: 100%;
  }

  .fleur-left {
    display: inline-block;
    margin: 0 35px 8px 0;
  }

  .fleur-right {
    margin: 0 0 8px 35px;
  }

  /* centers the last row if there is an uneven number of cards left */
  .col-centered {
    float: none;
    margin: 0 auto;
  }
}


/*================== ABOUT US SECTION =====================*/

/* MOBILE STYLINGS */

.who {
  margin-bottom: 40px;
}

#about-words-box-1, #about-words-box-2 {
  display: flex;
  text-align: center;
  padding: 30px;
}

#about-words-box-1 {
  background-color: var(--primary-color);
  color: white;
}

/* combined with flex set on the parent, this margin auto centers the text in the div */
#about-words-box-1 p, #about-words-box-2 p {
  margin: auto;
}

.about-img {
  width: 100%
}

.supportBox {
  margin-bottom: 30px;
}

.pickTN {
  padding: .175rem .75rem;
}

/* DESKTOP SIZES ON UP */
@media only screen and (min-width: 770px) {
  #about-words-box-1, #about-words-box-2 {
    padding: 60px 100px;
    /* justify-content: center; */
  }

  .supportText {
    display: inline;
    margin-right: 10px;
  }
}


/*================== FOOTER SECTION =====================*/

/* MOBILE STYLINGS */

.footer-box {
  background-color: var(--primary-color);
  color: white;
}

.footer-logo-box {
  margin: 30px auto 10px auto;
  display: flex;
}

.contact-us-box {
  margin: 30px auto 60px auto;
  display: flex;
}

.small-white-logo-box, .contact-us-text {
  margin: auto;
}

.small-white-logo, .small-title {
  margin: 0 0 5px 0;
}

.small-white-logo {
  height: 55px;
}

.small-title {
  font-family: black-chancery;
  float: right;
  font-size: 32px;
  font-weight: 100;
  padding: 11px 0 0 6px;
}

/* moves the apostrophe ' in charpier's to the correct position for the Parisienne font only */
/* .small-title > span > span {
  position: relative;
  top: 6px;
  right: 3px;
} */

.citayy {
  font-size: 14px;
}

.contact-us-text p {
  margin: 0 0 5px 0;
  font-size: 18px;
}

.contact-us-text span {
  display: block;
  font-size: 16px;
}

.contact-info {
  padding-left: 20px;
}

.contact-info a {
  margin-left: 10px;
  font-size: 18px;
  line-height: 1.9;
}



/* TABLET SIZES ON UP */
@media only screen and (min-width: 480px) {
  /* moves the apostrophe ' in charpier's to the correct position for the Parisienne font only */
  /* .small-title > span > span {
    position: relative;
    top: 6px;
    right: 3px;
  } */

  .footer-logo-box, .contact-us-box {
    margin: 30px auto 20px auto;
    display: flex;
  }
}




/* animations on the header */
@keyframes moveInLeft {
  /* start */
  0% {
    /* keep animations at two properties, for browser optimization. opacity and transform are all you need */
    opacity: 0;
    /* starting out with the element invisible, and at the end below it gets set to 1 */
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  /* finish */
  100% {
    opacity: 1;
    transform: translate(0);
    /* translate 0 because we want it to look as it does right now at the end*/ } }

@keyframes moveInRight {
  /* start */
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  /* finish */
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInBottom {
  /* start */
  0% {
    opacity: 0;
    transform: translateY(4rem); }
  /* finish */
  100% {
    opacity: 1;
    transform: translate(0); } }